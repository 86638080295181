var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { BrowserInfo, Icon, Toggle } from "notes";
import styled from "styled-components";
import { useVideoRecordContext } from "../VideoRecordContextProvider";
import { IODeviceSelector } from "./IODeviceSelector";
import { Div } from "../../../Div";
import { useDivTop, useWindowInnerHeight } from "../../../../hooks";
import { Overlay, OverlayBackdrop } from "../../..";
var autoPlayPreferenceProps = {
    header: "Auto-Play Recordings",
    text: "Turn on to allow recorded video messages to start playing after\n          recording (prior to being sent) to check for quality and satisfaction.\n          This will also apply to completed videos when a fan is selected.",
};
var saveCameraPreferenceProps = {
    header: "Save camera/microphone preferences",
    text: "When turned off, Shout Out will automatically select the default camera and microphone devices for your browser.",
};
var Dropdown = styled(Overlay)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 450px;\n  margin-left: -410px;\n  margin-top: -15px;\n  z-index: 21;\n  @media all and ", " {\n    position: fixed;\n    top: 56px;\n    border-radius: 0;\n    left: 0;\n    padding: 0;\n    margin: 0;\n    width: 100%;\n    box-shadow: none;\n    border: none;\n  }\n"], ["\n  width: 450px;\n  margin-left: -410px;\n  margin-top: -15px;\n  z-index: 21;\n  @media all and ", " {\n    position: fixed;\n    top: 56px;\n    border-radius: 0;\n    left: 0;\n    padding: 0;\n    margin: 0;\n    width: 100%;\n    box-shadow: none;\n    border: none;\n  }\n"])), function (props) { return props.theme.media.mobile; });
var PreferenceWrapper = styled(Div)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  @media all and ", " {\n    flex-direction: row-reverse;\n  }\n"], ["\n  @media all and ", " {\n    flex-direction: row-reverse;\n  }\n"])), function (props) { return props.theme.media.mobile; });
var PreferencesDescription = styled(Div)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  @media all and ", " {\n    margin-right: 40px;\n  }\n"], ["\n  @media all and ", " {\n    margin-right: 40px;\n  }\n"])), function (props) { return props.theme.media.mobile; });
var ToggleWrapper = styled(Div)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  @media all and ", " {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    text-align: center;\n  }\n"], ["\n  @media all and ", " {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    text-align: center;\n  }\n"])), function (props) { return props.theme.media.mobile; });
var SettingsBackdrop = styled(OverlayBackdrop)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  z-index: 20;\n"], ["\n  z-index: 20;\n"])));
var Preference = function (_a) {
    var checked = _a.checked, onToggle = _a.onToggle, header = _a.header, text = _a.text;
    return (React.createElement(Div, { mt_25: true, mb_15: true },
        React.createElement(PreferenceWrapper, { spaceBetween: true, dflex: true },
            React.createElement(ToggleWrapper, { mr_13: true },
                React.createElement(Toggle, { disabled: false, checked: checked, onChange: onToggle })),
            React.createElement(PreferencesDescription, { mt_n4: true },
                React.createElement(Div, { bold: true, fs_17: true }, header),
                React.createElement(AutoPlayText, { fs_11: true, mt_3: true }, text)))));
};
export var Settings = function (_a) {
    var onClose = _a.onClose, _b = _a.withAutoPlayOption, withAutoPlayOption = _b === void 0 ? true : _b, _c = _a.onHelpClick, onHelpClick = _c === void 0 ? null : _c, setIsTroubleshootModalOpen = _a.setIsTroubleshootModalOpen;
    var _d = useVideoRecordContext(), preferences = _d.preferences, setPreferences = _d.setPreferences;
    var _e = useDivTop(), ref = _e.ref, divTop = _e.divTop;
    var windowInnerHeight = useWindowInnerHeight();
    var updatePreference = function (preference, value) {
        setPreferences(function (prev) {
            var _a;
            return __assign(__assign({}, prev), (_a = {}, _a[preference] = value, _a));
        });
    };
    var headerUI = (React.createElement(Div, { forDesktop: true, alignCenter: true, spaceBetween: true },
        React.createElement(Div, { fs_25: true, bold: true }, "Settings"),
        React.createElement(Div, { clickable: true, onClick: onClose },
            React.createElement(Icon, { platform: true, name: "Close" }))));
    var deviceSelectorsUI = (React.createElement(Div, { forDesktop: true, pb_20: true },
        React.createElement(Divider, { mb_15: true }),
        React.createElement(CameraAndMicText, { fs_14: true, bold: true }, "Camera and Microphone (Desktop Only)"),
        React.createElement(IODeviceSelector, null)));
    var isSafari = BrowserInfo.getBrowser().name === "Safari" ||
        BrowserInfo.getBrowser().name === "Mobile Safari";
    return (React.createElement(Div, null,
        React.createElement(SettingsBackdrop, { forDesktop: true, onClick: onClose }),
        React.createElement(Dropdown, { innerHeight: windowInnerHeight, ref: ref, top: divTop, onClose: onClose },
            React.createElement(Wrapper, null,
                headerUI,
                withAutoPlayOption && (React.createElement(Preference, __assign({}, autoPlayPreferenceProps, { checked: preferences.autoPlay, onToggle: function (checked) { return updatePreference("autoPlay", checked); } }))),
                !isSafari && (React.createElement(React.Fragment, null,
                    deviceSelectorsUI,
                    React.createElement(Divider, null),
                    React.createElement(Div, { forDesktop: true },
                        React.createElement(Link, { onClick: function () {
                                setIsTroubleshootModalOpen(true);
                            } }, "Camera or microphone not working?")))),
                onHelpClick && (React.createElement(Link, { forMobile: true, onClick: onHelpClick }, "Help & FAQs")),
                !isSafari && (React.createElement(Div, { forDesktop: true },
                    React.createElement(Preference, __assign({}, saveCameraPreferenceProps, { checked: preferences.saveSelectedVideoAudioDeviceIds, onToggle: function (checked) {
                            return updatePreference("saveSelectedVideoAudioDeviceIds", checked);
                        } }))))))));
};
var Link = styled(Div)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  color: #27b0ff;\n  border-bottom: solid 1px #e2e6e8;\n  font-weight: bold;\n  padding: 12px 0;\n  font-size: 17px;\n  user-select: none;\n  :hover {\n    cursor: pointer;\n    opacity: 0.7;\n  }\n"], ["\n  color: #27b0ff;\n  border-bottom: solid 1px #e2e6e8;\n  font-weight: bold;\n  padding: 12px 0;\n  font-size: 17px;\n  user-select: none;\n  :hover {\n    cursor: pointer;\n    opacity: 0.7;\n  }\n"])));
var Divider = styled(Div)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  background-color: #e2e6e8;\n  height: 1px;\n  width: 100%;\n"], ["\n  background-color: #e2e6e8;\n  height: 1px;\n  width: 100%;\n"])));
var AutoPlayText = styled(Div)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  font-style: italic;\n"], ["\n  font-style: italic;\n"])));
var CameraAndMicText = styled(Div)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  color: #a6aeb2;\n  font-size: 14px;\n  font-weight: bold;\n"], ["\n  color: #a6aeb2;\n  font-size: 14px;\n  font-weight: bold;\n"])));
var Wrapper = styled(Div)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  color: #222222;\n  padding: 17px 20px;\n  margin-bottom: 10px;\n  @media all and ", " {\n    padding-top: 0;\n  }\n"], ["\n  color: #222222;\n  padding: 17px 20px;\n  margin-bottom: 10px;\n  @media all and ", " {\n    padding-top: 0;\n  }\n"])), function (props) { return props.theme.media.mobile; });
export default Settings;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
