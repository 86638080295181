import moment from "moment";

export const getDomain = () =>
  location.protocol +
  "//" +
  location.hostname +
  (location.port ? ":" + location.port : "");

export const getDisplayDate = (date, format = "MMMM DD") =>
  date ? moment(date.seconds * 1000).format(format) : null;

const padZero = (str, len = 2) => {
  const zeros = new Array(len).join("0");
  return (zeros + str).slice(-len);
};

export const invertColor = (hex, bw = true) => {
  if (hex.indexOf("#") === 0) {
    hex = hex.slice(1);
  }
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    throw new Error("Invalid HEX color.");
  }
  let r: any = parseInt(hex.slice(0, 2), 16),
    g: any = parseInt(hex.slice(2, 4), 16),
    b: any = parseInt(hex.slice(4, 6), 16);
  if (bw) {
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#000000" : "#FFFFFF";
  }
  r = (255 - r).toString(16);
  g = (255 - g).toString(16);
  b = (255 - b).toString(16);
  return "#" + padZero(r) + padZero(g) + padZero(b);
};

export const getDisplayPrice = (cents) => `$${(cents / 100).toFixed(2)}`;
