import { Div } from "@max/common-ui";
import styled, { css } from "styled-components";
import { Icon } from "notes";
import Dropdown from "components/common/Dropdown";
import { useState } from "react";
import { useMediaQuery } from "@max/common-ui";
import { theme } from "theme";
import { paddingLeftCss, paddingRightCss } from "@max/common-ui";
import {
  OverlayBackdrop as BackdropBase,
  OverlayContainer as ModalContainer,
} from "@max/common-ui";
import Metatags from "./Metatags";
import videoSvg from "../../../assets/video.svg";
import ShareModal from "components/common/ShareModal";
import { shareMenuItems } from "@max/common-ui";

const Avatar = styled.img`
  border-radius: 100%;
  user-select: none;
`;

const Backdrop = styled(BackdropBase)`
  background: black;
  opacity: 0.38;
`;

const Divider = styled(Div)`
  height: 4px;
  background: #1f1f1f;
`;

const Button = styled(Div)`
  display: flex;
  user-select: none;
  display: flex;
  align-items: center;
  ${Div} {
    border-bottom: 2px solid transparent;
  }
  :hover {
    cursor: pointer;
    opacity: 0.8;
    ${Div} {
      border-bottom: 2px solid white;
    }
  }
  @media all and ${(props) => props.theme.media.mobile} {
    display: block;
    display: grid;
    place-items: center;
  }
`;

const ButtonText = styled(Div)`
  margin-left: 7px;
  font-size: 17px;
  font-weight: bold;
  @media all and ${(props) => props.theme.media.mobile} {
    margin-left: 0;
    font-size: 12px;
    margin-top: -20px;
    border: solid 1px black;
  }
`;

const MessageTextWrapper = styled(Div)`
  display: flex;
  flex-direction: column;
  @media all and ${(props) => props.theme.media.mobile} {
    flex-direction: column-reverse;
    ${paddingLeftCss};
    ${paddingRightCss};
    padding-top: 17px;
    background-image: url(${videoSvg});
    background-size: 150% 150%;
    background-position: -20% 20%;
    background-repeat: no-repeat;
    padding-bottom: 18px;
  }
`;

const ArtistGroupName = styled(Div)`
  @media all and ${(props) => props.theme.media.mobile} {
    margin-top: 15px;
  }
`;

const MessageText = styled(Div)`
  font-size: 25px;
  width: 392px;
  margin-top: 15px;
  @media all and ${(props) => props.theme.media.mobile} {
    margin-top: 0;
    padding: 0;
    margin: 0;
    width: auto;
    font-size: 17px;
  }
`;

const Actions = styled(Div)`
  svg {
    path {
      fill: white;
    }
  }
  margin-top: 40px;
  @media all and ${(props) => props.theme.media.mobile} {
    border-top: 1px solid #4f575b;
    border-bottom: 1px solid #4f575b;
    height: 65px;
    display: flex;
    margin-top: 0px;
    justify-content: space-around;
  }
`;

const Name = styled(Div)`
  width: 360px;
  overflow: hidden;
  word-wrap: break-word;
  overflow-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
  font-size: 25px;
  font-weight: bold;
  color: #9ca4a8;
  @media all and ${(props) => props.theme.media.mobile} {
    width: 100%;
    font-size: 17px;
    color: white;
  }
`;

const ShareMenuItem = styled(Div)`
  ${(props) =>
    !props.isFirst &&
    css`
      border-top: 1px solid #e2e6e8;
    `}
  ${(props) =>
    !props.isLast &&
    css`
      border-bottom: 1px solid #e2e6e8;
    `}
  user-select: none;
  :hover {
    cursor: pointer;
    background: #f3f3f3;
  }
  font-size: 17px;
  padding: 13px;
  display: flex;
  align-items: center;
  color: #222222;
`;

const dropdownCss = css`
  border-radius: 0;
  width: 290px;
  margin-top: -5px;
  margin-left: 30px;
`;

const Container = styled(Div)`
  background: black;
  color: white;
`;

const ShareMenu = ({ onClose }) => {
  return (
    <>
      {shareMenuItems().map((mi, index) => {
        const Share = mi.shareComponent;
        return (
          <Div key={index}>
            <Share
              style={{ outline: "none" }}
              url={window.location.href}
              onShareWindowClose={onClose}
            >
              <ShareMenuItem
                isFirst={index === 0}
                isLast={index === shareMenuItems.length - 1}
                key={index}
              >
                <Div w_16 mr_5>
                  {mi.icon}
                </Div>
                <Div ml_7>{mi.label}</Div>
              </ShareMenuItem>
            </Share>
          </Div>
        );
      })}
    </>
  );
};

const MessageDetail = ({ shoutout, message }) => {
  const [isShareDropdownOpen, setIsShareDropdownOpen] = useState(false);
  const downloadVideo = () => window.open(message?.download?.media);
  const {
    artistGroupName,
    assets: {
      icon: { path: shoutoutImageUrl },
    },
  } = shoutout;
  const isMobile = useMediaQuery(theme.media.mobile);

  const onCloseShareOptions = () => setIsShareDropdownOpen(false);

  const shareUI = (
    <>
      {!isMobile && (
        <Dropdown
          isOpen={isShareDropdownOpen}
          onClose={onCloseShareOptions}
          dropdownCss={dropdownCss}
        >
          <ShareMenu onClose={onCloseShareOptions} />
        </Dropdown>
      )}
      {isMobile && isShareDropdownOpen && (
        <>
          <Backdrop onClick={onCloseShareOptions} />
          <ModalContainer>
            <ShareModal onClose={onCloseShareOptions} />
          </ModalContainer>
        </>
      )}
      <Button ml_30 onClick={() => setIsShareDropdownOpen(true)}>
        <Icon name="Send" />
        <ButtonText>Share</ButtonText>
      </Button>
    </>
  );

  const downloadUI = (
    <>
      <Button onClick={downloadVideo}>
        <Icon name="Download" />
        <ButtonText>Download</ButtonText>
      </Button>
    </>
  );

  const forSomeoneElseText =
    message?.form?.type === "someone_else"
      ? `${` and ${message?.form?.requestedBy}`}`
      : "";
  const messageText = `A message for ${
    message?.form?.messageFor || message?.form?.requestedBy
  } from ${artistGroupName}${forSomeoneElseText}!`;
  return (
    <Container>
      <Metatags
        shoutout={shoutout}
        message={message}
        messageText={messageText}
      />
      <Div mcontainer>
        <MessageTextWrapper>
          <ArtistGroupName dflex alignCenter>
            <Avatar
              height={isMobile ? "24px" : "35px"}
              width={isMobile ? "24px" : "35px"}
              src={shoutoutImageUrl}
            />
            <Name ml_15>{artistGroupName}</Name>
          </ArtistGroupName>
          <MessageText>{messageText}</MessageText>
        </MessageTextWrapper>
      </Div>
      <Div forDesktop>
        <Divider mt_40 />
      </Div>
      <Actions dflex fs_17>
        {downloadUI}
        {shareUI}
      </Actions>
    </Container>
  );
};

export default MessageDetail;
