import styled, { css } from "styled-components";
import { Div } from "@max/common-ui";
import { Section, sectionPadding } from "@max/common-ui";
import { Icon, ButtonSecondary } from "notes";
import { useState } from "react";
import ShoutoutForm from "components/common/ShoutoutForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { useShoutoutRequestContext } from "./ShoutoutRequestContextProvider";
import { useAppContext } from "AppContextProvider";

const Subheader = styled(Div)`
  color: ${(props) => props.theme.colors.disabledText};
  font-size: 15px;
  font-weight: bold;
  margin-top: 18px;
  margin-bottom: 3px;
`;

const WarningMessage = styled(Div)`
  margin-top: 32px;
  border-top: solid 1px ${(props) => props.theme.colors.yellow20};
  border-bottom: solid 1px ${(props) => props.theme.colors.yellow20};
  background: ${(props) => props.theme.colors.yellow10};
  color: ${(props) => props.theme.colors.text};
  font-size: 17px;
  padding: 16px 60px;
  span {
    margin-left: 5px;
    color: ${(props) => props.theme.colors.linkDefault};
    text-decoration: underline;
    user-select: none;
    :hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
  @media all and ${(props) => props.theme.media.mobile} {
    margin-top: 0;
    border-top: none;
    font-size: 15px;
    padding: 20px 5%;
  }
`;

const Wrapper = styled(Section)`
  padding: 0 !important;
  border: solid 1px transparent;
  margin-top: 24px;
  ${(props) =>
    props.isRequestRejected &&
    css`
      margin-top: -100px;
    `}
  @media all and ${(props) => props.theme.media.mobile} {
    margin-top: 0;
    padding: 0 !important;
    border-radius: 0;
  }
`;

const Content = styled(Div)`
  ${sectionPadding};
`;

const Field = ({ label, value }) => {
  return (
    <>
      {value && (
        <Div mb_5>
          <Subheader>{label}</Subheader>
          <Div>{value}</Div>
        </Div>
      )}
    </>
  );
};

const Button = styled(ButtonSecondary)`
  margin-top: 40px;
  width: 392px;
  svg {
    path {
      fill: ${(props) => props.theme.colors.linkDefault};
    }
  }
  :hover,
  :focus {
    svg {
      path {
        fill: ${(props) => props.theme.colors.white};
      }
    }
  }
  @media all and ${(props) => props.theme.media.mobile} {
    width: 100%;
  }
`;

const WarningIcon = styled(Div)`
  color: ${(props) => props.theme.colors.orange40};
`;

const Summary = ({ isShoutoutReady, isRequestRejected = null, request }) => {
  const [isEdit, setIsEdit] = useState(false);
  const { shoutout, requestDoc } = useShoutoutRequestContext();
  const { setAppIsLoading } = useAppContext();
  const requestForm = request?.form;

  const onSubmitForm = async (values) => {
    setAppIsLoading(true);
    await requestDoc.private.ref.update({ form: values });
    setAppIsLoading(false);
    setIsEdit(false);
  };

  const summaryUI = (
    <>
      <Div fs_25 extraBold mt_n5>
        Summary
      </Div>
      <Field label="Who is this message for?" value={requestForm.messageFor} />
      <Field label="Relationship" value={requestForm.relation} />
      <Field label="Conversastion Starter" value={requestForm.starter} />
      <Field label="Message details" value={requestForm.message} />
    </>
  );

  const gotoPage = (path) => {
    window.open(`https://${process.env.REACT_APP_FIREBASE_AUTHDOMAIN}${path}`);
  };

  const warningMessageUI = (
    <WarningMessage>
      <Div dflex>
        <WarningIcon>
          <FontAwesomeIcon icon={faExclamationTriangle} />
        </WarningIcon>
        <Div ml_14>
          Unfortunately, your ShoutOut request will not be fulfilled. A refund
          back to the original payment method has been issued. Have additional
          questions?
          <span onClick={() => gotoPage("/contact")}>Contact Us.</span>
        </Div>
      </Div>
    </WarningMessage>
  );

  const editFormUI = (
    <>
      {!isEdit && (
        <Div mcontainer>
          <Content>
            <Div mb_5>
              {summaryUI}
              {!isRequestRejected && !isShoutoutReady && (
                <Button
                  onClick={() => {
                    setIsEdit(true);
                  }}
                >
                  <Icon tertiary name="Edit" />
                  <Div ml_10>Edit Message Request</Div>
                </Button>
              )}
            </Div>
          </Content>
        </Div>
      )}
    </>
  );

  return (
    <>
      <Wrapper c_text positionRelative isRequestRejected={isRequestRejected}>
        {isEdit && (
          <Div mcontainer>
            <Content>
              <ShoutoutForm
                shoutout={shoutout}
                request={requestForm}
                isEdit={true}
                onCancel={() => setIsEdit(false)}
                onSubmit={onSubmitForm}
              />
            </Content>
          </Div>
        )}
        {isRequestRejected && warningMessageUI}
        {editFormUI}
      </Wrapper>
    </>
  );
};

export default Summary;
