import styled from "styled-components";
import { Div } from "@max/common-ui";
import { Section, sectionPadding } from "@max/common-ui";
// add to notes
import { ShopIcon } from "components/common/icons";
import { usePaymentContext } from "components/PaymentContextProvider";
import { getDisplayPrice } from "components/utils";

const Divider = styled(Div)`
  height: 1px;
  background: ${(props) => props.theme.colors.gray20};
`;

const Wrapper = styled(Section)`
  padding: 0;
  margin-top: 24px;
  @media all and ${(props) => props.theme.media.mobile} {
    margin-top: 0;
    padding: 0;
  }
`;

const Content = styled(Div)`
  ${sectionPadding}
  color: ${(props) => props.theme.colors.text};
`;

const OrderSummaryTotal = styled(Div)`
  color: ${(props) => props.theme.colors.text};
  display: flex;
  align-items: center;
  padding: 17px 52px 20px 52px;
  @media all and ${(props) => props.theme.media.mobile} {
    padding-right: 0;
    padding-left: 0;
  }
`;

const Image = styled.img`
  height: 108px;
  width: 108px;
  @media all and ${(props) => props.theme.media.mobile} {
    height: 70px;
    width: 70px;
  }
`;

const OrderSummaryText = styled(Div)`
  font-size: 20px;
  @media all and ${(props) => props.theme.media.mobile} {
    font-size: 15px;
  }
`;

const ListItem = styled(Div)`
  padding: 15px 52px 5px 52px;
  @media all and ${(props) => props.theme.media.mobile} {
    padding-right: 0;
    padding-left: 0;
  }
`;

const Label = styled(Div)`
  font-size: 16px;
  color: ${(props) => props.theme.colors.text};
  font-weight: 500;
`;

const Summary = () => {
  const { shoutout, shoutoutDisplayPrice } = usePaymentContext();
  const {
    artistGroupName,
    assets: {
      icon: { path: shoutoutImageUrl },
    },
  } = shoutout;

  const price = shoutoutDisplayPrice;
  const orderTotal = shoutoutDisplayPrice;

  const orderSummaryUI = (
    <Div spaceBetween>
      <Div dflex>
        <Image src={shoutoutImageUrl} />
        <OrderSummaryText bold ml_20 mr_20>
          Personalized Video Message From {artistGroupName}
        </OrderSummaryText>
      </Div>
      <Div fs_17 bold ml_20>
        {getDisplayPrice(shoutout.unitPrice)}
      </Div>
    </Div>
  );

  const orderTotalUI = (
    <>
      <Div fs_17 bold mt_5>
        Total
      </Div>
      <Div dflex alignItemsEnd>
        <Div mt_n5>
          <Div mt_n25 fs_14 mr_5>
            USD
          </Div>
        </Div>
        <Div fs_25 extraBold>
          {orderTotal}
        </Div>
      </Div>
    </>
  );

  const processingFeeUI = (
    <ListItem spaceBetween>
      <Div c_gray30 fs_17>
        Processing Fee
      </Div>
      <Div c_gray30 fs_17>
        {getDisplayPrice(shoutout.processingFeeAmount)}
      </Div>
    </ListItem>
  );

  const orderSubtotalUI = (
    <ListItem spaceBetween alignItemsEnd mt_15>
      <Div mt_5 dflex alignCenter>
        <Label>Subtotal</Label>
      </Div>
      <Div dflex alignItemsEnd>
        <Div fs_17 bold>
          {getDisplayPrice(shoutout.unitPrice)}
        </Div>
      </Div>
    </ListItem>
  );

  return (
    <Wrapper>
      <Div mcontainer>
        <Content>
          <Div alignCenter mb_30 forDesktop>
            <ShopIcon width="32px" height="32px" />
            <Div fs_25 extraBold mt_1 ml_10>
              Order Summary
            </Div>
          </Div>
          {orderSummaryUI}
        </Content>
      </Div>
      <Divider />
      <Div mcontainer>
        {orderSubtotalUI}
        {processingFeeUI}
        <OrderSummaryTotal spaceBetween alignItemsEnd>
          {orderTotalUI}
        </OrderSummaryTotal>
      </Div>
    </Wrapper>
  );
};

export default Summary;
