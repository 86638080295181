import { Helmet } from "react-helmet";

const Metatags = ({ shoutout, message, messageText }) => {
  const {
    assets: { artistImageUrl },
  } = shoutout;
  const imageUrl = message.download.previewImageUrl || artistImageUrl;
  return (
    <Helmet>
      <title>Set.Live - Shout Outs</title>
      <meta name="title" content="Set.Live - Shout Outs" />
      <meta name="description" content={messageText} />

      <meta property="og:type" content="website" />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:title" content="Set.Live - Shout Outs" />
      <meta property="og:description" content={messageText} />
      <meta property="og:image" content={imageUrl} />

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={window.location.href} />
      <meta property="twitter:title" content="Set.Live - Shout Outs" />
      <meta property="twitter:description" content={messageText} />
      <meta property="twitter:image" content={imageUrl} />
    </Helmet>
  );
};

export default Metatags;
