import { fstore } from "firebase-tools";
import { createContext, useContext, useState } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { useParams } from "react-router";
import { getDisplayPrice } from "./utils";

const PaymentContext = createContext(null);

export const PaymentContextProvider = ({ children }) => {
  const [requestId, setRequestId] = useState(null);
  const { shoutoutId } = useParams<any>();
  const [isPaymentComplete, setIsPaymentComplete] = useState(false);
  const [customerInformation, setCustomerInformation] = useState(null);
  const [shoutout, isLoadingShoutout]: any = useDocumentData(
    fstore.doc(`shoutouts/${shoutoutId}`)
  );

  return (
    <PaymentContext.Provider
      value={{
        shoutoutDisplayPrice: shoutout
          ? getDisplayPrice(shoutout.unitPrice + shoutout.processingFeeAmount)
          : null,
        customerInformation,
        shoutout,
        shoutoutId,
        isPaymentComplete,
        isLoadingShoutout,
        requestId,
        setIsPaymentComplete,
        setCustomerInformation,
        setRequestId,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};

export const usePaymentContext = () => useContext(PaymentContext);
