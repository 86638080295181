import {
  PaymentContextProvider,
  usePaymentContext,
} from "components/PaymentContextProvider";
import {
  generatePath,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router";
import ShoutoutOrderForm from "./ShoutoutOrderForm";
import PaymentForm from "./PaymentForm";
import OrderConfirmation from "./OrderConfirmation";
import { useEffect } from "react";
import { ROUTES as APP_ROUTES } from "App";

export const ROUTES = {
  PAYMENT: "/payment",
  ORDER_CONFIRMATION: "/order-confirmation",
};

export const useShoutoutOrderRedirect = () => {
  const { shoutoutId, requestId, isPaymentComplete } = usePaymentContext();
  const history = useHistory();
  const match = useRouteMatch();
  const baseUrl = match.path;

  const gotoShoutoutOrderRoot = () =>
    history.push(generatePath(APP_ROUTES.SHOUTOUT_ORDER, { shoutoutId }));

  const gotoShoutoutOrderConfirmationPage = () =>
    history.push(
      generatePath(`${APP_ROUTES.SHOUTOUT_ORDER}${ROUTES.ORDER_CONFIRMATION}`, {
        shoutoutId,
      })
    );

  const inPaymentsPage =
    baseUrl === `${APP_ROUTES.SHOUTOUT_ORDER}${ROUTES.PAYMENT}`;
  const inOrderConfirmationPage =
    baseUrl === `${APP_ROUTES.SHOUTOUT_ORDER}${ROUTES.ORDER_CONFIRMATION}`;

  useEffect(() => {
    if (!requestId) {
      if (inPaymentsPage) gotoShoutoutOrderRoot();
      if (inOrderConfirmationPage) history.push(APP_ROUTES.ORDER_LOOKUP);
    }
    if (inPaymentsPage && isPaymentComplete)
      gotoShoutoutOrderConfirmationPage();
  }, []);
};

const ShoutoutOrder = () => {
  const match = useRouteMatch();
  const baseUrl = match.path;
  return (
    <PaymentContextProvider>
      <Switch>
        <Route exact path={`${baseUrl}${ROUTES.PAYMENT}`}>
          <PaymentForm />
        </Route>
        <Route exact path={`${baseUrl}${ROUTES.ORDER_CONFIRMATION}`}>
          <OrderConfirmation />
        </Route>
        <Route>
          <ShoutoutOrderForm />
        </Route>
      </Switch>
    </PaymentContextProvider>
  );
};

export default ShoutoutOrder;
