import { theme } from "@max/common-ui";

export const colorPalette = {
  blue20: "#26a6fa",
  blue30: "#217cc2",
  blue40: "#0D2137",
  purple40: "#2c334b",
  purple50: "#252c44",
  purple60: "#07091f",
  green20: "#64c566",
  gray05: "#F5F8FB",
  gray10: "#E0E3E5",
  gray20: "#9ca4a8",
  gray30: "#596266",
  yellow10: "#FFF9E9",
  yellow20: "#FFE7A8",
  orange40: "#FEB72D",
  background: "#F9F9F9",
};

theme.media = { ...theme.media, mobile: "(max-width: 1050px)" };
theme.media = { ...theme.media, mobileSmall: "(max-width: 600px)" };
theme.media = { ...theme.media, desktop: "(min-width: 1051px)" };
theme.media = {
  ...theme.media,
};
theme.colors = { ...theme.colors, ...colorPalette };

export { theme };
