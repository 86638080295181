import { Container } from "@max/common-ui";
import styled, { css } from "styled-components";
import { Div } from "@max/common-ui";
import { invertColor } from "components/utils";
import { SetLogo } from "@max/common-ui";

const HeaderContainer = styled(Div)`
  height: 78px;
  padding: 0;
  margin: 0;

  ${(props) =>
    props.backgroundColor
      ? css`
          background: ${props.backgroundColor};
        `
      : css`
          background: linear-gradient(45deg, #7c60d9 0%, #39a4a1 100%);
        `}

  svg {
    path {
      ${(props) =>
        props.backgroundColor
          ? css`
              fill: ${invertColor(props.backgroundColor)};
            `
          : css`
              fill: white;
            `}
    }
  }
  @media all and ${(props) => props.theme.media.mobile} {
    height: 48px;
  }
`;

const Header = ({ backgroundColor = null, withLogo = true }) => {
  return (
    <HeaderContainer backgroundColor={backgroundColor}>
      <Div mcontainer h100 justifyCenter>
        <Container alignCenter>
          <Div forDesktop alignCenter>
            {withLogo && <SetLogo />}
          </Div>
          <Div forMobile alignCenter>
            {withLogo && <SetLogo width="31px" height="16px" />}
          </Div>
        </Container>
      </Div>
    </HeaderContainer>
  );
};

export default Header;
