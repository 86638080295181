import { Section as SectionBase } from "@max/common-ui";
import { Div } from "@max/common-ui";
import styled, { css } from "styled-components";
import { Icon } from "notes";
import { useShoutoutRequestContext } from "./ShoutoutRequestContextProvider";
import { getDisplayDate } from "components/utils";
import { RequestStatus } from "@musicaudienceexchange/shoutout-interface/lib/firestore/shoutouts";

const CompleteIcon = styled(Div)`
  height: 16px;
  width: 16px;
  background: ${(props) => props.theme.colors.pillDuration};
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    path {
      fill: white;
    }
  }
`;

const IncompleteIcon = styled(Div)`
  height: 14px;
  width: 14px;
  border-radius: 100%;
  border: solid 1px ${(props) => props.theme.colors.disabledText};
`;

const Label = styled(Div)`
  margin-top: 10px;
  font-size: 14px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.text};
  ${(props) =>
    !props.isComplete &&
    css`
      color: ${(props) => props.theme.colors.disabledText};
    `};
  @media all and ${(props) => props.theme.media.mobile} {
    font-size: 11px;
  }
`;

const Date = styled(Div)`
  font-size: 14px;
  @media all and ${(props) => props.theme.media.mobile} {
    font-size: 11px;
  }
`;

const Event = ({ label, createdAt = null }) => {
  const isComplete = !!createdAt;
  const displayDate = createdAt ? getDisplayDate(createdAt) : null;

  return (
    <Div>
      <Div justifyCenter>
        {isComplete && (
          <CompleteIcon>
            <Icon indicator name="Check" width="8px" height="8px" />
          </CompleteIcon>
        )}
        {!isComplete && <IncompleteIcon />}
      </Div>
      <Div justifyCenter>
        <Div positionAbsolute>
          <Label isComplete={isComplete} justifyCenter>
            {label}
          </Label>
          {displayDate && (
            <Date justifyCenter c_border lite>
              {displayDate}
            </Date>
          )}
        </Div>
      </Div>
    </Div>
  );
};

const EventConntector = styled(Div)`
  height: 2px;
  margin: 0 11px;
  background: ${(props) => props.theme.colors.gray10};
  width: 100%;
  margin-top: 6px;
  ${(props) =>
    props.isComplete &&
    css`
      background: ${(props) => props.theme.colors.pillDuration};
    `}
`;

const EventWrapper = styled(Div)`
  display: flex;
  ${(props) =>
    !props.isFirst &&
    css`
      width: 100%;
    `}
`;

const Wrapper = styled(Div)`
  margin: 0 20px 50px 20px;
  width: 100%;
`;

const Section = styled(SectionBase)`
  @media all and ${(props) => props.theme.media.mobile} {
    padding-top: 20px;
    padding-bottom: 10px;
  }
`;

const LABELS = {
  [RequestStatus.received]: "Submitted",
  [RequestStatus.viewed]: "Reviewed",
  [RequestStatus.completed]: "Delivered",
  [RequestStatus.received]: "Submitted",
  [RequestStatus.refunded]: "Refunded",
};

const getTimelineEvents = (events) => {
  events.reverse();
  const getEvent = (type) => ({
    createdAt: events.find((e) => e.status === type)?.createdAt,
    label: LABELS[type],
  });
  const submittedEvt = getEvent(RequestStatus.received);
  const reviewedEvt = getEvent(RequestStatus.viewed);
  const refundedEvt = getEvent(RequestStatus.refunded);
  const completedEvt = getEvent(RequestStatus.completed);

  const timeline = [
    submittedEvt,
    reviewedEvt,
    refundedEvt.createdAt ? refundedEvt : completedEvt,
  ];
  return timeline.map((e) => ({ createdAt: e.createdAt, label: e.label }));
};

const Progress = () => {
  const {
    request: { events },
  } = useShoutoutRequestContext();
  return (
    <Div mcontainer>
      <Section dflex>
        <Wrapper>
          <Div positionRelative dflex w100>
            <Events events={getTimelineEvents([...events])} />
          </Div>
        </Wrapper>
      </Section>
    </Div>
  );
};

const Events = ({ events }) => {
  return (
    <>
      {events.map((evt, index) => {
        const isFirst = index === 0;
        return (
          <EventWrapper key={index} isFirst={isFirst}>
            {!isFirst && <EventConntector isComplete={!!evt?.createdAt} />}
            <Event {...evt} />
          </EventWrapper>
        );
      })}
    </>
  );
};

export default Progress;
