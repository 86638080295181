import { Div } from "@max/common-ui";
import Header, {
  ContentColumns,
  HeaderTextContainer,
  HeaderText,
} from "components/common/Header";
import { AppContainer, AppLayout } from "@max/common-ui";
import styled from "styled-components";
import { Icon } from "notes";
import PhoneNumberInput from "../../common/PhoneNumberInput";
import Summary from "./Summary";
import ContactInformation from "./ContactInformation";
import { useEffect } from "react";
import { theme } from "theme";
import { Footer, ShareIcons } from "@max/common-ui";
import { useMediaQuery } from "@max/common-ui";
import { MobileDivider, SectionHeader } from "@max/common-ui";
import { useShoutoutOrderRedirect } from "..";
import { usePaymentContext } from "components/PaymentContextProvider";
import { ROUTES } from "App";
import { getDomain } from "components/utils";
import { shoutoutI } from "firebase-tools";
import { generatePath } from "react-router";

const HeaderIcon = styled(Div)`
  border-radius: 100%;
  background: ${(props) => props.theme.colors.orange40};
  height: 72px;
  width: 72px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    path {
      fill: ${(props) => props.theme.colors.text};
    }
  }
`;

const PhoneNumberSection = styled(Div)`
  margin-top: -80px;
  @media all and ${(props) => props.theme.media.mobile} {
    margin-top: 0;
  }
`;

const OrderConfirmation = () => {
  useShoutoutOrderRedirect();
  const { shoutout, setIsPaymentComplete } = usePaymentContext();

  useEffect(() => {
    setIsPaymentComplete(true);
  }, []);

  return <>{shoutout && <OrderConfirmationWithData />}</>;
};

const OrderConfirmationWithData = () => {
  const {
    requestId,
    shoutout,
    shoutoutId,
    customerInformation: { email, paymentData },
  } = usePaymentContext();
  const {
    hideSetliveHeaderLogo,
    assets: {
      headerLarge: { path: backgroundImageUrl },
      headerSmall: { path: mobileBackgroundImageUrl },
    },
    responseTimeDays,
  } = shoutout;

  const shoutoutUrl = `${getDomain()}${generatePath(ROUTES.SHOUTOUT_ORDER, {
    shoutoutId,
  })}`;

  const isMobile = useMediaQuery(theme.media.mobile);

  useEffect(() => {
    if (isMobile) document.body.style.backgroundColor = theme.colors.white;
    else document.body.style.backgroundColor = theme.colors.background;
  }, [isMobile]);

  const onSubmitPhoneNumber = async (phoneNumber) => {
    shoutoutI.functions().shoutouts.setPhone({
      requestId,
      paymentIntentId: paymentData?.paymentIntent?.id,
      phoneNumber: phoneNumber,
    });
  };

  const headerUI = (
    <HeaderTextContainer>
      <HeaderIcon>
        <Icon tertiary name="Video" height="40" width="40" />
      </HeaderIcon>
      <HeaderText text="Order Confirmation" />
    </HeaderTextContainer>
  );

  return (
    <AppLayout>
      <Header
        withLogo={!hideSetliveHeaderLogo}
        shareUrl={shoutoutUrl}
        backgroundImageUrl={
          isMobile ? mobileBackgroundImageUrl : backgroundImageUrl
        }
      >
        {headerUI}
      </Header>
      <AppContainer>
        <ContentColumns>
          <Div>
            <PhoneNumberSection positionRelative>
              <PhoneNumberInput
                onSubmit={onSubmitPhoneNumber}
                shoutout={shoutout}
                email={email}
                responseTimeDays={responseTimeDays}
              />
            </PhoneNumberSection>
            <MobileDivider />
            <SectionHeader>Order Summary</SectionHeader>
            <Summary />
            <MobileDivider />
            <ContactInformation />
          </Div>
          <Div forDesktop>
            <ShareIcons shareUrl={shoutoutUrl} />
          </Div>
        </ContentColumns>
      </AppContainer>
      <Footer
        background={isMobile ? theme.colors.white : theme.colors.background}
      />
    </AppLayout>
  );
};

export default OrderConfirmation;
