import { fstore } from "firebase-tools";
import { createContext, useContext, useEffect, useState } from "react";
import {
  useCollectionData,
  useDocument,
  useDocumentData,
} from "react-firebase-hooks/firestore";
import { useParams } from "react-router";

const ShoutoutRequestContext = createContext(null);

export const useRequest = (requestId) => {
  const [
    requestPublicDataDoc,
    isLoadingRequestPublicDataDoc,
  ]: any = useDocument(fstore.doc(`shoutout_requests/${requestId}`));
  const [
    requestPrivateDataDoc,
    isLoadingPrivatePublicDataDoc,
  ]: any = useDocument(
    fstore.doc(`shoutout_requests/${requestId}/private/data`)
  );
  const [events, isLoadingEvents] = useCollectionData(
    fstore.collection(`shoutout_requests/${requestId}/events`)
  );

  const isLoading =
    isLoadingRequestPublicDataDoc ||
    isLoadingPrivatePublicDataDoc ||
    isLoadingEvents;

  const request = {
    ...requestPublicDataDoc?.data(),
    ...requestPrivateDataDoc?.data(),
    events,
  };

  return {
    request,
    requestDoc: {
      public: requestPublicDataDoc,
      private: requestPrivateDataDoc,
    },
    isLoading,
  };
};

export const ShoutoutRequestContextProvider = ({ children }) => {
  const { shoutoutId, requestId } = useParams<any>();
  const [shoutout, isLoadingShoutout]: any = useDocumentData(
    fstore.doc(`shoutouts/${shoutoutId}`)
  );
  const { request, requestDoc, isLoading: isLoadingRequest } = useRequest(
    requestId
  );
  const isLoading = isLoadingShoutout || isLoadingRequest;
  return (
    <ShoutoutRequestContext.Provider
      value={{
        shoutoutId,
        requestId,
        shoutout,
        isLoading,
        request,
        requestDoc,
      }}
    >
      {children}
    </ShoutoutRequestContext.Provider>
  );
};

export const useShoutoutRequestContext = () =>
  useContext(ShoutoutRequestContext);
