import { createContext, useContext, useEffect, useState } from "react";
import { fb } from "./firebase-tools";
import LogRocket from "logrocket";

const AppContext = createContext(null);
const userData = JSON.parse(localStorage.getItem("user"));

export const AppContextProvider = ({ children }) => {
  const [user, setUser] = useState(userData);
  const [appIsLoading, setAppIsLoading] = useState(false);

  useEffect(() => {
    return fb.auth().onAuthStateChanged((_user) => {
      localStorage.setItem("user", JSON.stringify(_user));
      setUser(_user);
    });
  }, []);

  useEffect(() => {
    LogRocket.identify(user?.uid, {
      name: user?.displayName,
      email: user?.email,
      phone: user?.phoneNumber,
    });
  }, [user]);

  return (
    <AppContext.Provider
      value={{
        user,
        isLoggedIn: !!user,
        appIsLoading,
        setUser,
        setAppIsLoading,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
