import { Div } from "@max/common-ui";
import styled from "styled-components";

export const Backdrop = styled(Div)`
  position: fixed;
  top: 0px;
  left: 0px;
  margin: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 99999999;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) => props.css}
`;

export const DropdownDiv = styled(Div)`
  max-height: ${(props) => props.maxHeight};
  width: ${(props) => props.width};
  margin-left: ${(props) => props.marginLeft};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "6px")};
  background-color: white;
  border: solid 1px#F9FAFB;
  position: absolute;
  border-radius: 4px;
  overflow-y: auto;
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    opacity: 0.3;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: #d2d4e0;
    opacity: 0.3;
    border-radius: 3px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f8f9fb;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    border-radius: 3px;
    background-color: #d2d4e0;
  }
  box-shadow: 0 8px 20px 0 rgba(51, 72, 115, 0.13);
  z-index: 10000000000;
  ${(props) => props.css}
`;

const Dropdown = ({
  onClose,
  isOpen = false,
  children,
  dropdownCss = null,
  backdropCss = null,
}) => {
  return (
    <>
      {isOpen && (
        <Div>
          <Backdrop onClick={onClose} css={backdropCss} />
          <DropdownDiv css={dropdownCss}>{children}</DropdownDiv>
        </Div>
      )}
    </>
  );
};

export default Dropdown;
