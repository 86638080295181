import styled from "styled-components";
import { Div, OverlayContainer } from "@max/common-ui";
import { Loader } from "notes";

const LoadingBackdrop = styled(Div)`
  position: fixed;
  top: 0px;
  left: 0px;
  margin: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 99999999;
  background: ${(props) => props.theme.colors.border};
  opacity: 0.2;
`;

const LoaderContainer = styled(OverlayContainer)`
  pointer-events: none;
`;

const AppLoading = () => (
  <>
    <LoadingBackdrop />
    <LoaderContainer>
      <Div w_300 mt_100>
        <Div positionRelative>
          <Loader />
        </Div>
      </Div>
    </LoaderContainer>
  </>
);

export default AppLoading;
