import { Div } from "@max/common-ui";
import styled, { css } from "styled-components";
import { Section } from ".";
import { Wrapper } from "./ContactInformation";
import { Icon } from "notes";
import {
  PaymentRequestButtonElement,
  CardElement,
} from "@stripe/react-stripe-js";

const SectionWrapper = styled(Wrapper)`
  padding-top: 45px;
  padding-bottom: 50px;
  @media all and ${(props) => props.theme.media.mobile} {
    padding-top: 24px;
  }
`;

const ELEMENT_OPTIONS: any = {
  style: {
    paymentRequestButton: {
      type: "buy",
      theme: "dark",
    },
  },
};

const PaymentButton = styled(Div)`
  height: 41px;
  color: ${(props) => props.theme.colors.white};
  background: ${(props) => props.theme.colors.blue40};
  border-radius: 4px;
  display: flex;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  user-select: none;
  margin-top: 20px;
  ${(props) =>
    props.isProcessingPayment &&
    css`
      opacity: 0.8;
    `}
  :hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

const CardElementContainer = styled(Div)`
  margin-top: 18px;
  border: solid 1px #a9afc0;
  padding: 9px;
  border-radius: 5px;
`;

const headerUI = (
  <>
    <Div fs_17 c_text bold mb_10>
      Credit Card
    </Div>
    <Div alignCenter ml_n4>
      <Icon name="Lock" />
      <Div fs_15 c_text ml_4>
        All transactions are secure and encrypted.
      </Div>
    </Div>
  </>
);

const cardElementStyles = {
  style: {
    base: {
      color: "#1F1F1F",
      fontSize: "16px",
      "::placeholder": {
        color: "#ADB3C3",
      },
    },
    invalid: {
      iconColor: "#EA2D36",
      color: "#EA2D36",
    },
    complete: {
      iconColor: "#cbf4c9",
    },
  },
};

const Link = styled("span")`
  font-weight: 700;
  user-select: none;
  :hover {
    cursor: pointer;
    opacity: 0.8;
  }
  margin: 0 5px;
  color: ${(props) => props.theme.colors.action};
`;

const PaymentElements = ({
  cardError,
  onSubmit,
  paymentRequest,
  onPaymentRequestButtonClick,
  displayPaymentRequestButton,
  isProcessingPayment,
}) => {
  const gotoPage = (path) => {
    window.open(`https://${process.env.REACT_APP_FIREBASE_AUTHDOMAIN}${path}`);
  };

  const legalTextUI = (
    <Div mt_20 fs_10 c_border>
      <Div>
        By submitting your purchase, you agree to Set.Live’s
        <Link
          onClick={() => {
            gotoPage("/privacy");
          }}
        >
          privacy policy
        </Link>
        and
        <Link
          onClick={() => {
            gotoPage("/terms");
          }}
        >
          terms of service.
        </Link>
      </Div>
    </Div>
  );

  const paymentRequestButtonUI = (
    <>
      {displayPaymentRequestButton && (
        <Div>
          <Div justifyCenter mt_25 mb_25 bold>
            OR
          </Div>
          <PaymentRequestButtonElement
            onClick={onPaymentRequestButtonClick}
            options={{
              ...ELEMENT_OPTIONS,
              paymentRequest,
            }}
          />
        </Div>
      )}
    </>
  );

  return (
    <Section centered mcontainer>
      <SectionWrapper>
        {headerUI}
        <CardElementContainer>
          <CardElement options={cardElementStyles} />
        </CardElementContainer>
        {cardError && <ErrorMessage msg={cardError} />}
        <PaymentButton
          onClick={onSubmit}
          isProcessingPayment={isProcessingPayment}
        >
          Pay
        </PaymentButton>
        {paymentRequestButtonUI}
        {legalTextUI}
      </SectionWrapper>
    </Section>
  );
};

const ErrorMessageText = styled(Div)`
  font-size: 15px;
  color: ${(props) => props.theme.colors.inputErrorBorder};
  svg {
    path {
      fill: ${(props) => props.theme.colors.inputErrorBorder};
    }
  }
`;

const ErrorMessage = ({ msg }) => {
  return (
    <ErrorMessageText mt_12 dflex alignCenter>
      <Icon form name="RemoveCircle" />
      <Div ml_10>{msg}</Div>
    </ErrorMessageText>
  );
};

export default PaymentElements;
