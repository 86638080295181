import { Div } from "@max/common-ui";
import ShoutoutOrderForm from "components/ShoutoutOrder/ShoutoutOrderForm";
import ShoutoutRequest from "components/ShoutoutRequest";
import VideoMessage from "components/VideoMessage";
import { Component } from "react";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorMsg: "", componentErrorStack: "" };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true,
      errorMsg: error.toString(),
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      componentErrorStack: errorInfo?.componentStack.toString(),
    });
  }

  render() {
    const { errorMsg, hasError, componentErrorStack } = this.state as any;

    const videoMessageNotFoundUI = (
      <Div ml_30>
        <h2>Video Message Doesn’t Exist</h2>
      </Div>
    );

    const shoutoutRequestNotFoundUI = (
      <Div ml_30>
        <h2>Shoutout Request Doesn’t Exist</h2>
      </Div>
    );

    const shoutoutNotFoundUI = (
      <Div ml_30>
        <h2>Shoutout Doesn’t Exist</h2>
      </Div>
    );

    const defaultUI = (
      <Div ml_30>
        <h2>Something went wrong.</h2>
      </Div>
    );
    if (hasError && process.env.REACT_APP_ENV !== "development") {
      if (componentErrorStack.includes(ShoutoutRequest.name))
        return shoutoutRequestNotFoundUI;
      if (componentErrorStack.includes(ShoutoutOrderForm.name))
        return shoutoutNotFoundUI;
      if (componentErrorStack.includes(VideoMessage.name))
        return videoMessageNotFoundUI;
      return defaultUI;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
