import { usePaymentContext } from "components/PaymentContextProvider";
import { Helmet } from "react-helmet";

const Metatags = () => {
  const { shoutout } = usePaymentContext();
  const {
    introduction,
    artistGroupName,
    assets: {
      icon: { path: shoutoutImageUrl },
    },
  } = shoutout;

  return (
    <Helmet>
      <title>Shout Outs - {artistGroupName}</title>
      <meta name="title" content={`Shout Outs - ${artistGroupName}`} />
      <meta name="description" content={introduction} />

      <meta property="og:type" content="website" />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:title" content={`Shout Outs - ${artistGroupName}`} />
      <meta property="og:description" content={introduction} />
      <meta property="og:image" content={shoutoutImageUrl} />

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={window.location.href} />
      <meta
        property="twitter:title"
        content={`Shout Outs - ${artistGroupName}`}
      />
      <meta property="twitter:description" content={introduction} />
      <meta property="twitter:image" content={shoutoutImageUrl} />
    </Helmet>
  );
};

export default Metatags;
